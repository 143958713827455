import ReactDOM from 'react-dom';

import IntakeApp from 'apps/intake';
import { KeycloakService } from 'services/KeycloakService';
import { setCurrentAppName } from 'utils/currentApp';
import { initDatadog } from 'utils/datadog';
import { setTokenName } from 'utils/http';

import * as serviceWorker from './serviceWorker';

import 'antd/es/style/css';

setCurrentAppName('intake');
setTokenName('intake');
initDatadog('intake');

KeycloakService.initKeycloak('check-sso', () => {
  ReactDOM.render(<IntakeApp />, document.getElementById('root'));
});

document.getElementById('initial-loader')?.remove();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
