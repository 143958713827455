import Providers from 'providers';
import { setDefaultAuthMethod } from 'utils/http';

import Intake from './Intake';
import { AntdOverrides, GlobalStyles } from './IntakeApp.styled';

import { CONNECT_TO_EXTERNAL_API } from 'constants/FeatureFlags';

if (CONNECT_TO_EXTERNAL_API) setDefaultAuthMethod('token');

export default function SpecialistApp() {
  return (
    <Providers>
      <Intake />
      <AntdOverrides />
      <GlobalStyles />
    </Providers>
  );
}
