import { createGlobalStyle } from 'styled-components';

import { fullHeightMobile } from 'styled/mixins';

export const AntdOverrides = createGlobalStyle`
  :root {
    background-color: #191c1f;
  }

  body {
    height: auto;
    background-color: inherit;
  }

  *,
  & *::before,
  & *::after {
    box-sizing: inherit;
    font-family: inherit;
  }

  /* Button */

  .ant-btn {
    font-weight: 500;

    &.ant-btn-sm {
      font-weight: 700 !important;
      font-size: 13px;
      letter-spacing: 0.04em !important;
    }
  }

  .ant-btn.ant-btn-primary {
    font-family: inherit;
    border: 1px solid transparent !important;
    box-shadow: 0 2px 8px rgba(0,0,0,0.25);
    background-color: #5f59ff;
    color: #fff;
    text-shadow: 0 1px 1px rgba(0,0,0,0.25);
    border-radius: 4px;
    padding-top: 0;
    padding-bottom: 0;
    letter-spacing: 0.02em;
    -webkit-font-smoothing: antialiased;

    &.ant-btn-sm {
      border-radius: 3px;
      height: 22px;
    }

    &:focus {
      border: 1px solid transparent !important;
      background-color: #5f59ff;
      border-radius: 5px;
      outline: none;
      box-shadow: 0 2px 8px rgba(0,0,0,0.25);
    }

    &:hover {
      background-color: #5f59ff;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0.06),
        rgba(255, 255, 255, 0.06)
      );
      border: 1px solid transparent !important;
      box-shadow: 0 2px 8px rgba(0,0,0,0.25);
    }

    &:active {
      background-color: #5f59ff;
      border: 1px solid transparent !important;
      background-image: linear-gradient(rgba(0, 0, 0, 0.225), rgba(0, 0, 0, 0.225));
      box-shadow: none;
      color: rgba(255, 255, 255, 0.7);
      transition: none;
    }

    &:disabled {
      color: #fff !important;
      opacity: 0.35 !important;
      background-image: none !important;
      border: 1px solid transparent !important;
      box-shadow: none !important;
      filter: grayscale(0.25);
    }

    &.ant-btn-background-ghost {
      border: 1px solid #5f59ff;
      box-shadow: none;
      color: #5f59ff;

      &:hover {
        background-color: #f6f6ff !important;
      }

      &:active {
        background-color: #e2dfff !important;
      }
    }
  }

  /* Form */
  .ant-form-item-label.ant-col-xs-24.ant-col-sm-8 {
    white-space: normal;
  }

  .mp-form-item .ant-form-item-label label {
    color: #fff;
  }

  /* Modal */
  .ant-modal {
    max-width: 100vw !important;
  }
  .ant-modal-content {
    background-color: transparent !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  .ant-layout-sider-zero-width-trigger {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 48px;
    right: -48px;
    height: 50px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &::before {
      content: '☰';
      display: block;
      text-align: center;
      color: rgba(255,255,255,0.95);
      font-size: 23px;
    }

    & i {
      display: none;
    }
  }

  .ant-notification-notice-close {
    display: none;
  }

  /* Popover */
  .mp-popover.ant-popover .ant-popover-arrow {
    display: none !important;
  }

  .mp-popover .ant-popover-inner {
    box-sizing: border-box !important;
    margin: 0 12px;
    background-color: #414d53;
    color: #fff;
    border-radius: 6px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2),
                0 3px 8px 1px rgba(0,0,0,0.125),
                0 1px 3px 0 rgba(0,0,0,0.1),
                0 0 0 1px rgba(0,0,0,0.225) !important;
  }

  .ant-popover-inner > * {
    border-radius: inherit;
    box-sizing: border-box !important;
  }

  .ant-popover-inner-content {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    box-sizing: border-box !important;

    & > * {
      border-radius: inherit;
      box-sizing: border-box !important;
    }
  }

  .mp-popover .ant-popover-title {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255,255,255,0.95);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
    background-color: rgba(255,255,255,0.08);
    box-shadow: 0 1px 0 rgba(0,0,0,0.15), inset 0 1px 0 rgba(255,255,255,0.15);
    font-size: 14px;
    letter-spacing: 0.08em;
    text-shadow: 0 1px 0 rgba(0,0,0,0.5);
    font-weight: 600;
    min-height: 48px;
    padding: 0 !important;
    margin: 0;
    user-select: none;
    text-rendering: geometricPrecision;
  }
`;

export const GlobalStyles = createGlobalStyle`
  :root {
    background-color: var(--page-background);
    color: #fff;
    box-sizing: border-box;
    ${fullHeightMobile}
  }

  html,
  body,
  #root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    ${fullHeightMobile}
  }
  body {
    font-family: "Neue Montreal", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
    background-color: inherit;
    & *,
    & *::before,
    & *::after {
      font-family: inherit;
      box-sizing: inherit;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
