import { lazy, Suspense, useCallback, useEffect, useState } from 'react';

import { useUnit } from 'effector-react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

import { Wrapper } from 'apps/specialist/components/Layout';
import LoginPromptContainer from 'apps/specialist/containers/LoginPromptContainer';
import { useIsAuthed } from 'apps/specialist/hooks/useIsAuthed';
import { getUser, logout, userStore } from 'apps/specialist/state/user';
import AppAlert from 'components/AppAlert';
import { useHeapIdentifyUser } from 'hooks/useHeapIdentifyUser';

import { hasIntakePermissions } from '../../utils/intake/hasIntakePermission';

const IntakeContainer = lazy(() => import('apps/intake/containers/IntakeContainer'));
const NavbarContainer = lazy(() => import('apps/specialist/containers/NavbarContainer'));
const IntakeCaptureModal = lazy(() => import('apps/intake/containers/IntakeCaptureModal'));

export default function Intake() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { user } = useUnit(userStore);

  const isAuthed = useIsAuthed();

  const [modalVisible, setModalVisible] = useState(false);

  const openModal = useCallback(() => setModalVisible(true), []);
  const closeModal = useCallback(() => setModalVisible(false), []);

  // initial fetch of user data
  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    async function handleInvalidPermissions() {
      await logout();
      history.replace('/login');
    }
    if (user && !hasIntakePermissions(user.effectivePermissions)) {
      handleInvalidPermissions();
    } else if (isAuthed === false && pathname !== '/login') {
      history.replace('/login');
    }
  }, [isAuthed, pathname, history, user]);

  useHeapIdentifyUser({
    authenticated: isAuthed,
    user,
  });

  return (
    <>
      <AppAlert />
      <Wrapper>
        <Suspense fallback={null}>{isAuthed && <NavbarContainer />}</Suspense>
        <Switch>
          <Route exact path="/">
            <Suspense fallback={null}>
              {isAuthed && (
                <>
                  <IntakeContainer onBeginCapture={openModal} />
                  <IntakeCaptureModal visible={modalVisible} onClose={closeModal} />
                </>
              )}
            </Suspense>
          </Route>
          <Route exact path="/login">
            <LoginPromptContainer disableSSOAuth />
          </Route>
        </Switch>
      </Wrapper>
    </>
  );
}
